import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'typeface-roboto';
import React, { useState, useEffect, useRef } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
import { Flex } from 'reflexbox';
import styled from '@emotion/styled';
import posed from 'react-pose';
import { useOutsideAlerter } from '../hooks/outsideBlur';
Amplify.configure(awsconfig);
const Boxe = posed.div({
  visible: {
    opacity: 1,
    applyAtEnd: { display: 'block' },
    transition: { duration: 150 },
  },
  hidden: {
    opacity: 0,
    applyAtEnd: { display: 'none' },
    transition: { duration: 150 },
  },
});

const Header = styled(Flex)`
  width: 100%;
  height: 75px;
  background-color: transparent;
  margin-bottom: 20px;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
`;

const LoginButton = styled(Flex)`
  width: 95px;
  height: 35px;
  background-color: #1b1e23;
  margin: 40px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 8px 8px, rgba(0, 0, 0, 0.22) 0px 2px 2px;
  p {
    position: relative;
    color: darkgrey;
    font-family: roboto;
    font-size: 1.4rem;
    margin: auto;
    :hover {
      color: lightgrey;
    }
  }
  :hover {
    cursor: pointer;
    transform: scale(1.03);
  }
  :active {
    transform: scale(1);
  }
`;

const LoginWindow = styled(Flex)`
  flex-direction: column;
  position: relative;
  border-radius: 4px;
  min-width: 300px;
  height: 400px;
  padding: 10px;
  background-color: #1b1e23;
  top: 240px;
  right: 25px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 8px 8px, rgba(0, 0, 0, 0.22) 0px 2px 2px;
`;

const Input = styled.input`
  :focus {
    outline: 0;
  }
  background-color: #202327;
  color: white;
  border: 0;
  font-family: roboto !important;
  font-size: 1.1rem !important;
  margin: 17px auto 10px auto;
  text-align: center;
  height: 35px;
  width: 80%;
  /* border-radius: 4px; */
  ::-webkit-input-placeholder {
    font-size: 1.1rem;
    font-style: italic;
  }
  :focus::-webkit-input-placeholder {
    color: transparent;
  }
  :-webkit-autofill::first-line {
    font-family: roboto !important;
    font-size: 1.1rem !important;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0 30px #202327 inset;
  }
`;
const LoginButtonT = styled(LoginButton)`
  margin: 20px auto 0px auto;

  background-color: #282c34;
  box-shadow: none;
  :hover {
    transform: scale(1);
  }
  width: 120px;
  font-style: oblique;
  :first-child {
    font-style: normal !important;
    margin: 20px auto auto auto;
  }
`;
const AlwaysOn = props => {
  const { promiseInProgress } = usePromiseTracker({ area: 'login-area' });
  const [showWin, setShow] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [showSignUp, setSignUp] = useState(false);
  const [form, setForm] = useState({
    usrname: '',
    pw: '',
    email: '',
    confirmcode: '',
  });
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    setShow(false);
    setSignUp(false);
  });
  useEffect(() => {
    Auth.currentSession();
    setLoggedIn(true);
  }, []);

  const handleFormOnChange = (e, key) => {
    setForm({ ...form, [key]: e.target.value });
  };

  const handleSignup = async () => {
    await trackPromise(
      Auth.signUp({
        username: form.usrname,
        password: form.pw,
        attributes: { email: form.email },
      }).then(() => {
        setConfirm(true);
      }),
      'login-area',
    );
  };

  const handleConfirm = async () => {
    await trackPromise(
      Auth.confirmSignUp(form.usrname, form.confirmcode),
      'login-area',
    );
    setConfirm(false);
    setSignUp(false);
    setShow(!showWin);
    setLoggedIn(!loggedIn);
  };

  const handleLogin = async () => {
    await trackPromise(
      Auth.signIn(form.usrname, form.pw).then(() => Auth.currentSession()),
      'login-area',
    );
    setShow(!showWin);
    setLoggedIn(!loggedIn);
  };

  return (
    <Header>
      <Boxe className="box" pose={showWin ? 'visible' : 'hidden'}>
        <LoginWindow key="one" ref={wrapperRef}>
          {promiseInProgress ? (
            <Flex m="auto">
              <Loader
                type="Rings"
                color="#00BFFF"
                height={100}
                width={100}
                timeout={30000}
              />
            </Flex>
          ) : (
            <Flex flexDirection="column">
              {confirm ? (
                <Flex flexDirection="column">
                  <Input
                    placeholder="Confirm Code"
                    onChange={e => handleFormOnChange(e, 'confirmcode')}
                  />
                  <LoginButtonT onClick={handleConfirm}>
                    <p>Confirm</p>
                  </LoginButtonT>
                </Flex>
              ) : (
                <Flex flexDirection="column">
                  <Input
                    id="Username"
                    placeholder="Username"
                    autoComplete
                    onChange={e => handleFormOnChange(e, 'usrname')}
                  />
                  <Input
                    id="Password"
                    type="password"
                    placeholder="Password"
                    onChange={e => handleFormOnChange(e, 'pw')}
                  />
                  {showSignUp ? (
                    <Input
                      id="Email"
                      placeholder="Email"
                      autoComplete
                      onChange={e => handleFormOnChange(e, 'email')}
                    />
                  ) : (
                    <LoginButtonT onClick={handleLogin}>
                      <p>Login</p>
                    </LoginButtonT>
                  )}
                  <LoginButtonT
                    onClick={() => {
                      (showSignUp && handleSignup()) || setSignUp(true);
                    }}
                  >
                    <p>Sign up</p>
                  </LoginButtonT>
                </Flex>
              )}
            </Flex>
          )}
        </LoginWindow>
      </Boxe>
      <Boxe className="box" pose={!showWin ? 'visible' : 'hidden'}>
        {(loggedIn && (
          <LoginButton
            onClick={() => {
              Auth.signOut();
              setLoggedIn(false);
            }}
          >
            <p>Logout</p>
          </LoginButton>
        )) || (
          <LoginButton onClick={() => setShow(!showWin)}>
            <p>Login</p>
          </LoginButton>
        )}
      </Boxe>
    </Header>
  );
};
export default AlwaysOn;
