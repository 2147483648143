const orgDataS = [
  { name: 'TSLA', region: 'US' },
  {
    name: 'NVDA',
    region: 'US',
  },
  {
    name: 'AMAT',
    region: 'US',
  },
  {
    name: 'NTAP',
    region: 'US',
  },
  {
    name: 'CSCO',
    region: 'US',
  },
  {
    name: 'MSFT',
    region: 'US',
  },
  {
    name: 'ZBRA',
    region: 'US',
  },
  {
    name: 'COLM',
    region: 'US',
  },
  {
    name: 'CTRN',
    region: 'US',
  },
  {
    name: 'EQUEY',
    region: 'US',
  },
  {
    name: 'HYMTF',
    region: 'US',
  },
  {
    name: 'SRPT',
    region: 'US',
  },
  {
    name: 'MAWHF',
    region: 'US',
  },
  { name: 'TCLHF', region: 'US' },
];
export const initBarChartData = [
  19.51184547340743,
  13.973918486238045,
  54.76095153318413,
  127.8960616770942,
  26.62986755558901,
];
export const initMatrixLabels = ['AMAT', 'NTAP', 'MSFT', 'TSLA', 'AAPL'];
export const initMatrixData = [
  {
    name: 'AAPL',
    data: [
      0.8951384602961289,
      0.45375706885807204,
      0.9656825070242266,
      0.8710000594910458,
      1.0000000000000002,
    ],
  },

  {
    name: 'TSLA',
    data: [
      0.7466387799172156,
      0.2608036505086713,
      0.7613990523628614,
      0.9999999999999998,
    ],
  },
  {
    name: 'MSFT',
    data: [0.9075494605283934, 0.5268990262126233, 1],
  },
  {
    name: 'NTAP',
    data: [0.5844652178491647, 1.0000000000000002],
  },
  {
    name: 'AMAT',
    data: [1],
  },
];
