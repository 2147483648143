/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:29409f37-b555-4480-9b74-c3dc33d2ef3b",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_8KsOtY4Fr",
    "aws_user_pools_web_client_id": "45ocs3drm3dlghc3vnkph0i09o",
    "oauth": {}
};


export default awsmobile;
