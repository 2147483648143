import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'typeface-roboto';
import AsyncSelect from 'react-select/async';
import { VscDiffRemoved, VscDiffAdded } from 'react-icons/vsc';
import { AiOutlineStock } from 'react-icons/ai';
import { usePromiseTracker } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { Flex, Box } from 'reflexbox';
import { getVolatillity, getCorrelationMatrix } from './functions.js';
import styled from '@emotion/styled';
import ReactSlider from 'react-slider';
import {
  initMatrixData,
  initMatrixLabels,
  initBarChartData,
} from './stockdata';

const Wrapper = styled(Flex)`
  font-family: roboto;
`;

const StyledThumb = styled(Flex)`
  width: 26px;
  margin: auto;
  border-radius: 999px;
  font-family: roboto;
  top: 0;
  bottom: 0;
  font-size: 1.1rem;
  background-color: black;

  :focus {
    outline: none;
  }
  p {
    color: lightgrey;
    margin: auto;
  }
  :hover {
    cursor: pointer;
  }
`;
const Thumb = (props, state) => (
  <StyledThumb {...props}>
    <p>{state.valueNow}</p>
  </StyledThumb>
);

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background-color: #1d1e1f;
  border-radius: 999px;

  p {
    font-size: 1.1rem;
    line-height: 1.6rem;
    margin: auto 5px auto 5px;
  }
  color: #606060;
  font-family: roboto;
  overflow: hidden;
`;

const Track = (props, state) => (
  <StyledTrack {...props} index={state.index}>
    <p>Year range..</p>
  </StyledTrack>
);

const Slider = styled(ReactSlider)`
  width: 290px;
  height: 25px;
`;
const ChartWrapper = styled(Flex)`
  margin-top: 25px;
  * {
    color: black;
  }
`;

const SearchField = styled(AsyncSelect)`
  width: 320px;
  height: 35px;
  font-size: 1rem;
  line-height: 1.25rem;
  font-family: roboto;
  * {
    background-color: #1d1e1f;

    ::-webkit-scrollbar {
      width: 0.3rem;
    }
    ::-webkit-scrollbar-track {
      -webkit-border-radius: 10px;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 4px;
      border-radius: 4px;
      background-color: #282c34;
    }
    &:hover::-webkit-scrollbar-thumb {
      display: inline;
      -webkit-border-radius: 4px;
      border-radius: 4px;
      background-color: #282c34;
    }
  }
  [class*='control'] {
    border: 0;
    border-radius: 4px;
    background-color: #1d1e1f;
  }

  [class*='IndicatorsContainer'] {
    border-radius: 0 4px 4px 0;
  }
  [class*='indicatorContainer'] {
    border-radius: 0 4px 4px 0;
  }
  [class*='control']:focus-within {
    * {
      color: white !important;
    }
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;

    [class*='placeholder'] {
      display: none;
    }
  }
  [class*='MenuList'] {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
  }
  [class*='option'] {
    white-space: pre-wrap;
    line-height: 1.25rem;
    color: darkgrey;
    background-color: #1d1e1f;
    :first-line {
      color: white;
    }
    :hover {
      background-color: #282c34;
      cursor: pointer;
    }
  }
`;

const FormWrapper = styled(Flex)`
  margin: 25px 0;
`;
const LineWrapper = styled(Flex)`
  flex-grow: 1;
  background: #202327;
  margin: 3px 3px;
  padding: 7px 12px;
  border-radius: 4px;
`;
const StockIcon = styled(AiOutlineStock)`
  font-size: 1.7rem;
  color: #e5e8e6;
  margin: 2px 7px 0 auto;
`;
const CalcButton = ({ onClick }) => (
  <Flex
    onClick={onClick}
    css={`
      position: relative;
      cursor: pointer;
      height: 35px;
      width: 130px;
      box-shadow: 0px 3px 4px 1px black;
      border-radius: 3px;
      margin: 30px auto auto auto;
      :active {
        box-shadow: 0px 1px 1px black;
        top: 1px;
        right: 1px;
      }
      transition: all 30ms;
    `}
  >
    <Flex
      css={`
        font-size: 1.3rem;
        margin: auto 5px auto 5px;
        padding: 2px;
        /* font-weight: 20px; */

        font-family: roboto;
        color: #e5e8e6;
      `}
    >
      Calculate
    </Flex>
    <StockIcon />
  </Flex>
);

const RemoveButton = styled(VscDiffRemoved)`
  cursor: pointer;
  font-size: 1.8rem;
  color: darkred;
  :hover {
    color: red;
  }
  transition: all 200ms;
  margin: auto 0 auto 15px;
`;
const AddButton = styled(VscDiffAdded)`
  cursor: pointer;
  font-size: 1.8rem;
  color: darkgreen;
  :hover {
    color: lightgreen;
  }
  transition: all 200ms;

  margin: 10px 15px;
`;

const Input = styled.input`
  :focus {
    outline: 0;
  }
  background-color: #1d1e1f;
  color: white;
  border: 0;
  font-family: roboto !important;
  font-size: 1.1rem !important;
  margin: auto 0;
  margin-left: 15px;
  text-align: center;
  height: 23px;
  width: 80px;
  :first-of-type {
    margin-left: 0px;
  }
  ::-webkit-input-placeholder {
    font-size: 0.9rem;
    font-style: italic;
  }
  :focus::-webkit-input-placeholder {
    color: transparent;
  }

  :-webkit-autofill::first-line {
    font-family: roboto !important;
    font-size: 1.1rem !important;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0 30px #1d1e1f inset;
  }
`;

const Label = styled.label`
  color: lightgrey !important;
  margin: 8px 13px;
  text-align: center;
  font-size: 1.2rem;
  font-family: roboto;
  width: 80px;
`;

const orgData = [
  {
    symbol: 'AAPL',
    region: 'US',
  },
  {
    symbol: 'TSLA',
    region: 'US',
  },
  {
    symbol: 'MSFT',
    region: 'US',
  },
  {
    symbol: 'NTAP',
    region: 'US',
  },
  {
    symbol: 'AMAT',
    region: 'US',
  },
];

const Home = props => {
  const { promiseInProgress } = usePromiseTracker();
  const [searchState, setSearch] = useState({ value: '' });
  const [state, setState] = useState({
    sliderVal: 10,
    meanCorr: 55,
    stocks: [...orgData],
    options: {
      chart: {
        toolbar: { show: false },
        height: 600,
        type: 'heatmap',
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        padding: {
          right: 20,
        },
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val) {
            return `${Math.round(val * 100)}%`;
          },
        },
      },
      colors: ['#FF0000'],
      //
      yaxis: {
        labels: {
          show: true,
          style: {
            fontSize: '12px',
            colors: 'darkgrey',
          },
        },
      },
      xaxis: {
        labels: {
          show: true,
          style: {
            fontSize: '12px',
            colors: 'darkgrey',
          },
        },
        type: 'category',
        categories: initMatrixLabels,
      },
      title: {
        offsetX: 15,
        text: 'Correlation',
        align: 'center',
        style: {
          color: 'white',
        },
      },
    },
    series: [...initMatrixData],
  });

  const [barchart, setBarChart] = useState({
    series: [
      {
        name: 'Volatility',
        data: initBarChartData,
      },
    ],

    options: {
      chart: {
        toolbar: { show: false },
        height: 350,
        type: 'bar',
      },

      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return Math.round(val);
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#FFFFEA'],
        },
      },
      tooltip: {
        enabled: true,
        onDatasetHover: {
          highlightDataSeries: false,
        },
        x: { show: false },
        y: {
          show: false,
          formatter: function (val) {
            return Math.round(val);
          },
        },
        z: { show: false },
        marker: { show: false },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        },
      },

      xaxis: {
        categories: initMatrixLabels,
        position: 'bot',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          style: {
            fontSize: '12px',
            colors: 'darkgrey',
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      title: {
        text: 'Volatility',
        align: 'center',
        style: {
          color: 'white',
        },
      },
    },
  });

  const handleChange = e => {
    const type = e.target.className.split(' ')[0];
    if (['symbol', 'region'].includes(type)) {
      let stocks = [...state.stocks];
      stocks[e.target.dataset.id][type] = e.target.value.toUpperCase();
      setState({ ...state, stocks: [...stocks] });
    } else {
      setState({ [e.target.symbol]: e.target.value.toUpperCase() });
    }
  };

  const addStock = (symbol, region = '') => {
    setState({
      ...state,
      stocks: [...state.stocks, { symbol: symbol, region: region }],
    });
  };
  const addEmpyStock = e => {
    e.preventDefault();
    setState({
      ...state,
      stocks: [...state.stocks, { symbol: '', region: '' }],
    });
  };

  const removeStock = (e, idx) => {
    const filtered = state.stocks.filter((val, index) => index !== idx);
    e.preventDefault();

    setState({
      ...state,
      stocks: [...filtered],
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const _result = getCorrelationMatrix(state.stocks, state.sliderVal);
    getBarChart();
    const result = await _result;
    console.log('result: ', result);
    getStockData(result.data.data);
  };
  const getStockData = stocks => {
    let heatmapValues = [];
    let xaxisValues = [];
    let totalCorr = 0;
    for (let i = 0; i < state.stocks.length; i++) {
      for (let y = 0; y < stocks[i].length; y++) {
        totalCorr += stocks[i][y];
      }
      heatmapValues.push({
        name: state.stocks[i].symbol,
        data: [...stocks[i].reverse().slice(0, state.stocks.length - i)],
      });
      xaxisValues.unshift(state.stocks[i].symbol);
    }

    const meanCorr = totalCorr / (stocks[0].length * stocks[0].length);

    setState({
      ...state,
      meanCorr: Math.round(meanCorr * 100),
      series: [...heatmapValues],
      options: {
        ...state.options,
        xaxis: {
          ...state.options.xaxis,
          type: 'category',
          categories: [...xaxisValues],
        },
      },
    });
  };

  const getBarChart = async e => {
    const data = (await getVolatillity(state.stocks, state.sliderVal)).data
      .data;
    console.log('result: ', data);

    let chartValues = [];
    let xaxisValues = [];
    for (const [key, value] of Object.entries(data)) {
      chartValues.unshift(value);
      xaxisValues.unshift(state.stocks[key].symbol);
    }

    setBarChart({
      ...barchart,
      series: [
        {
          name: 'Volatility',
          data: [...chartValues],
        },
      ],
      options: {
        ...barchart.options,
        xaxis: {
          ...barchart.options.xaxis,
          type: 'category',
          categories: [...xaxisValues],
        },
      },
    });
  };

  const { stocks } = state;

  const loadOptions = async (inputValue, callback) => {
    const result = await fetch(
      `https://yahoo-finance-low-latency.p.rapidapi.com/v6/finance/autocomplete?query=${inputValue}&lang=en`,
      {
        method: 'GET',
        headers: {
          'x-rapidapi-key': process.env.REACT_APP_API_KEY,
          'x-rapidapi-host': 'yahoo-finance-low-latency.p.rapidapi.com',
        },
      },
    )
      .then(async response => {
        return await response.json();
      })
      .catch(err => {
        console.error(err);
      });
    const values = result.ResultSet.Result.map(stock => ({
      label: `${stock.name} \n${stock.exchDisp}`,
      value: stock.symbol,
    }));
    callback(values);
  };
  const handleInputChange = value => {
    setSearch({ value });
  };

  return (
    <Wrapper flexDirection="column" alignItems="center">
      <SearchField
        loadOptions={loadOptions}
        cacheOptions
        defaultOptions
        placeholder="Find assets..."
        value={searchState.value}
        onInputChange={handleInputChange}
        onChange={selectValue => {
          setSearch({ value: '' });
          addStock(selectValue.value);
        }}
      />
      <FormWrapper>
        <form
          spellCheck="false"
          onSubmit={handleSubmit}
          onChange={handleChange}
        >
          <Flex flexDirection="column">
            <Flex>
              <Label>Symbol</Label>
              <Label>Region</Label>
            </Flex>
            {stocks.map((val, idx) => {
              let stockId = `stock-${idx}`;
              let regionId = `region-${idx}`;
              return (
                <Flex transition="all 1s" flexDirection="row">
                  <LineWrapper key={idx}>
                    <Input
                      type="text"
                      name={stockId}
                      data-id={idx}
                      id={stockId}
                      value={stocks[idx].symbol}
                      className="symbol"
                    />
                    <Input
                      type="text"
                      name={regionId}
                      placeholder="Optional"
                      data-id={idx}
                      id={regionId}
                      value={stocks[idx].region}
                      className="region"
                    />

                    <RemoveButton
                      onClick={e => removeStock(e, idx)}
                    ></RemoveButton>
                  </LineWrapper>
                </Flex>
              );
            })}
          </Flex>
          <Flex flexDirection="column" alignItems="flex-end">
            <AddButton onClick={addEmpyStock}>Add</AddButton>
            <CalcButton onClick={handleSubmit} />
          </Flex>
        </form>
      </FormWrapper>
      <Slider
        min="1"
        max="10"
        invert
        minDistance="1"
        defaultValue="10"
        renderTrack={Track}
        renderThumb={Thumb}
        onChange={val => setState({ ...state, sliderVal: val })}
      />
      {promiseInProgress ? (
        <Flex minWidth="350px" minHeight="365px" mt="20px">
          <Box m="auto">
            <Loader
              type="Audio"
              color="#00BFFF"
              height={100}
              width={100}
              timeout={30000} //3 secs
            />
          </Box>
        </Flex>
      ) : (
        <ChartWrapper alignItems="center" mt="20px">
          <Box minWidth="350px">
            <Chart
              options={barchart.options}
              series={barchart.series}
              type="bar"
              height={350}
            />
          </Box>
          <Box minWidth="350px">
            <Chart
              options={state.options}
              series={state.series}
              type="heatmap"
              height={350}
            />
          </Box>
          <Label>Mean: {state.meanCorr}%</Label>
        </ChartWrapper>
      )}
    </Wrapper>
  );
};
export default Home;
