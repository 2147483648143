import { trackPromise } from 'react-promise-tracker';
const POST = async (route, body) => {
  const data = await fetch(route, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  return await data.json();
};

const GET = async route => {
  const data = await fetch(route, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await data.json();
};

export const getCorrelationMatrix = async (stocks, range, interval) =>
  await trackPromise(
    GET(
      `/api/v1/correlation-matrix?assets=${compileString(
        stocks,
        range,
        interval,
      )}`,
    ),
  );

export const getVolatillity = async (stocks, range, interval) =>
  await trackPromise(
    GET(`/api/v1/volatility?assets=${compileString(stocks, range, interval)}`),
  );

export const compileString = (stocks, range = 10, interval = 1) =>
  `${Object.values(stocks)
    .reduce((acc, stock) => {
      acc += `${stock.symbol},`;
      return acc;
    }, '')
    .slice(0, -1)}&range=${range}y&interval=${interval}d`;
