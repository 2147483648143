import './App.css';
import Home from './pages/home.js';
// import { AmplifySignOut, withAuthenticator } from '@aws-amplify/ui-react';
import AlwaysOn from './components/authheader';

function App() {
  return (
    <div className="App">
      <AlwaysOn />
      <Home />
    </div>
  );
}

export default App;
